<template>
<div>
  Eligibility Rules
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EligibilityRules',
  computed: mapState({

  }),
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
